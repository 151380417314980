
  /// add package
  $('.add_package').click(function(){
    var cur_items = $('.modal .items').attr('data-items');
    var new_item = parseInt(cur_items) + 1;
    
    var html = '<div class="row"><div class="item" data-item="'+ new_item +'">';
    html += '<div class="field col-md-2">';
    html += '<input type="number" name="count_'+ new_item +'" min="0" id="count" class="weight" value="1" required>';
    html += '</div>';
    html += '<div class="field col-md-6">';
    html += '<input type="number" min="0" name="length_'+ new_item +'" id="length" placeholder="cm" class="small length" required>';
    html += '<input type="number" min="0" name="width_'+ new_item +'" id="width" placeholder="cm" class="small width" required>';
    html += '<input type="number" min="0" name="height_'+ new_item +'" id="height" placeholder="cm" class="small height" required>';
    html += '</div>';
    html += '<div class="field col-md-2">';
    html += ' <input type="number" name="weight_'+ new_item +'" min="0" id="weight" class="weight" placeholder="kg" required>';
    html += '</div>';
    html += '<div class="field col-md-2">';
    html += '<i class="fa fa-times" data-item="'+ new_item +'"</i>';
    html += '</div>';
    html += '</div></div>';

    $('.modal .items').attr('data-items', new_item);
    $('.modal .items').append(html);

  });

  $('.more_packages').click(function(e){
    e.preventDefault();
    $('body').addClass('modal_open');
    $('.modal_overlay').velocity('fadeIn');
    $('.modal input[name="length_1"]').val($('input[name="length"]').val());
    $('.modal input[name="width_1"]').val($('input[name="width"]').val());
    $('.modal input[name="height_1"]').val($('input[name="height"]').val());
    $('.modal input[name="weight_1"]').val($('input[name="weight"]').val());
    $('.modal input#from_location_second').val($('input#from_location').val());
    $('.modal input[name="place_id"]').val($('input[name="place_id"]').val());
  });

  $(document).on('click', '.modal_overlay .modal .close', function(){
    $('body').removeClass('modal_open');
    $('.modal_overlay').velocity('fadeOut');
    $('.modal .items').attr('data-items', 1);

    $('.items .item').each(function(){
      var num = $(this).attr('data-item');
      if(num !== '1') {
        $(this).remove();
      }
    });
  });

  $(document).on('click', '.modal .items .fa-times', function() {
    var item = $(this).attr('data-item');
    $('.item[data-item="' + item +'"]').parent().remove();
  });

  $('button[name="calculate"]').click(function(e){
    var type_form = $(this).attr('data-type');

    if(type_form == 'multi'){
      var place = $('input#place_id_second').val();
      var c_items = $('.modal .items .item').length;
      var i = 1;
      var lang = $('input[name="lang"]').val();
      var packs = [];

      $('.modal .item').each(function(){
        var i = $(this).attr('data-item');
        var data = {};
        data.count = $('.modal .item[data-item="'+ i +'"] input#count').val();
        data.width = $('.modal .item[data-item="'+ i +'"] input#width').val();
        data.height = $('.modal .item[data-item="'+ i +'"] input#height').val();
        data.length = $('.modal .item[data-item="'+ i +'"] input#length').val();
        data.weight = $('.modal .item[data-item="'+ i +'"] input#weight').val();

        packs.push(data);
        i++;
      });

    } else {
      var lang = $('input[name="lang"]').val();
      var place = $('input#place_id').val();
      var packs = [];
      var data = {};
      data.length = $('input#length').val();
      data.width = $('input#width').val();
      data.height = $('input#height').val();
      data.weight = $('input#weight').val();
      data.count = 1;

      packs.push(data);
    }

    if(place.length > 0 && parseInt(packs[0].length) > 0 && parseInt(packs[0].width) > 0 && parseInt(packs[0].height) > 0 || parseInt(packs[0].weight) > 0 && place.length > 0) {
      $(this).html('<div class="loader"></div>');

      if(type_form == 'multi') {
        $("form.fields.form_multi").validate().cancelSubmit = true;
        $("form.fields.form_multi").submit();
      } else {
        $("form.fields.form").validate().cancelSubmit = true;
        $("form.fields.form").submit();
      }
    }
  });